@import '../mainColors';

.dashboard {
  .product-filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    .input-group {
      min-width: 220px;
    }
  }

  .products {
    display: flex;
    flex-wrap: wrap;
  }
}
