@import "../mainColors";

.login-container {
  display: flex;
  overflow: hidden;
  .login-left-side {
    width: 50%;
    height: 100vh;
    //background: url("../../images/login-bg.jpg") no-repeat bottom /cover;
    background-color: white;
    display: flex;
    .logo-title-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      svg{
        width: 400px;
        height: 100px;
      }
      .text-upper {
        line-height: 30px;
        font-size: 44px;
        color: #0F47C0;
        font-weight: 500;
        text-transform: uppercase;
        margin: 20px 0  ;
      }
      .page-title {
        font-size: 60px;
        margin-bottom: 10px;
        color: #0F47C0;
        font-weight: 500;
      }
      .animated-fadeIn {
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-name: fadeIn;
        animation-name: fadeIn;
        .text-info {
          line-height: 1.618;
          font-size: 32px;
          margin-bottom: 0;
          color: #0F47C0;
          font-weight: 500;
        }
      }
    }
  }
  .login-right-side {
    width: 50%;
    height: 100vh;
    display: flex;
    align-items: center;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInRightBig;
    animation-name: fadeInRightBig;
    .animated-fadeInRightBig {
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-animation-name: fadeInRightBig;
      animation-name: fadeInRightBig;
      z-index: 10;
      width: 100%;
      padding: 30px;
      text-align: center;
      .login-mobile-logo{
        display: none;
      }
      .text-upper {
        text-transform: uppercase;
        margin: 0 0 25px;
        color: black;
        font-weight: 400;
        font-size: 24px;
      }
      .form-group {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        padding: 0 40px;
        .input-group {
          width: 100%;
          margin-bottom: 25px;
          position: relative;
          > span {
            position: absolute;
            top: 50%;
            user-select: none;
            left: 16px;
            color: #b4bdc6;
            -webkit-transition: color 0.3s ease;
            transition: color 0.3s ease;
            font-size: 18px;
            transform: translateY(-50%);
          }
          .visibility-icon {
            left: unset;
            right: 16px;
            height: 18px;
            cursor: pointer;
            transform: translateY(-50%);
            display: flex;
          }
          &.invalid {
            input {
              -webkit-animation-name: shake;
              animation-name: shake;
              animation-duration: .7s;
              animation-fill-mode: both;
              border: 1px solid $brandSecColor;
            }
            > span, .visibility-icon {
              -webkit-animation-name: iconShake;
              animation-name: iconShake;
              animation-duration: .7s;
              animation-fill-mode: both;
            }
          }
          input {
            width: 100%;
            box-shadow: none;
            height: 46px;
            position: relative;
            font-size: 14px;
            color: #495057;
            padding: 0 20px 0 45px;
            -webkit-transition: all 0.3s ease 0s;
            transition: all 0.3s ease 0s;
            border-radius: 2px;
            border: 1px solid $borderColor;
            &:focus {
              color: #495057;
              background-color: #fff;
              border-color: $brandColor !important;
              outline: none;
            }
          }
          input:focus ~ span {
            color: $brandColor;
          }
          input:focus ~ .visibility-icon {
            span {
              color: #696464;
            }
          }
        }
        .submiting-button {
          width: 100%;
          text-align: right;
        }
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-10px);
  }
  20%, 40%, 60%, 80% {
    transform: translateX(10px);
  }
}

@keyframes iconShake {
  0%, 100% {
    transform: translate(0, -50%);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translate(-10px, -50%);
  }
  20%, 40%, 60%, 80% {
    transform: translate(10px, -50%);
  }
}

@media screen and (max-width: 982px) {
  .login-container {
    justify-content: center;
    .login-left-side {
      display: none;
    }
    .login-right-side {
      //background: url("../../images/login-bg.jpg") no-repeat bottom /cover;
      background-color: #071D22;
      width: 100%;
      .animated-fadeInRightBig {
        .login-mobile-logo{
          display: block;
          height: 120px;
          margin: 0 auto 40px auto;
        }
        .text-upper {
          display: none;
        color: white;
      }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .login-container {
    .login-right-side {
      .animated-fadeInRightBig .text-upper {
        font-size: 20px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .login-container {
    .login-right-side {
      width: 100%;
    }
  }
}

@media screen and (max-width: 500px) {
  .login-container {
    .login-right-side {
      .animated-fadeInRightBig {
        padding: 20px;
        .text-upper {
          font-size: 18px;
        }
        .form-group {
          padding: 0 10px;
          .input-group {
            margin-bottom: 18px;
          }
          .submiting-button button {
            padding: 8px 30px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .login-container {
    .login-right-side {
      .animated-fadeInRightBig {
        padding: 15px;
        .text-upper {
          font-size: 16px;
        }
        .form-group {
          padding: 0;
          .input-group {
            margin-bottom: 18px;
            input {
              font-size: 13px;
              padding: 0 20px 0 35px;
              height: 42px;
            }
            > i {
              left: 10px;
            }
            .visibility-icon {
              right: 10px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .login-container {
    .login-right-side {
      .animated-fadeInRightBig {
        padding: 10px;
      }
    }
  }
}
